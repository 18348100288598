import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Link } from 'gatsby';

class ContactComplete extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Contact | Justin Kwan Lee</title>
          <meta name="description" content="Contact Justin Kwan Lee" />
        </Helmet>
        <div id="main">
          <section id="one">
            <h1>Submission complete</h1>
            <p>Thanks for your submission, I will get back to you shortly.</p>
            <br />
            <Link to="/">Return to Homepage</Link>
          </section>
        </div>
      </Layout>
    );
  }
}

export default ContactComplete;
